import React from "react"
import * as styles from "./controls.module.scss"

import ArrowLeftIcon from "../../images/arrow_left.inline.svg"
import ArrowRightIcon from "../../images/arrow_right.inline.svg"
import loadingGif from "../../images/ajax-loader.svg"

const Button = ({
  text,
  arrowDirection,
  enabled = true,
  onClick = () => {},
  loading = false,
}) => (
  <button
    disabled={!enabled}
    onClick={onClick}
    className={styles.button}
    style={{
      visibility: enabled ? "visible" : "hidden",
    }}
  >
    {arrowDirection === "left" && (
      <ArrowLeftIcon className={styles.arrow} style={{ marginRight: "8px" }} />
    )}
    {text}
    {arrowDirection === "right" &&
      (loading ? (
        <img
          alt="Lädt..."
          src={loadingGif}
          className={styles.loader}
          style={{ marginLeft: "8px" }}
        />
      ) : (
        <ArrowRightIcon
          className={styles.arrow}
          style={{ marginLeft: "8px" }}
        />
      ))}
  </button>
)

export default ({ leftButton, labelText, rightButton, ...otherProps }) => (
  <div className={styles.container} {...otherProps}>
    <div className={styles.buttonContainer}>
      <Button {...leftButton} arrowDirection="left" />
    </div>
    <p className={styles.label}>{labelText}</p>
    <div
      className={styles.buttonContainer}
      style={{ flexDirection: "row-reverse" }}
    >
      <Button {...rightButton} arrowDirection="right" />
    </div>
  </div>
)
