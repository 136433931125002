import React from "react"
import * as styles from "./input-field.module.scss"

import Checkmark from "../../images/checkmark.inline.svg"

export const TextInput = ({
  value = "",
  onChange = () => {},
  nativeOnChange = () => {},
  ...otherProps
}) => (
  <input
    type="text"
    className={styles.textInput}
    value={value}
    onChange={ev => {
      nativeOnChange(ev)
      onChange(ev.target.value)
    }}
    {...otherProps}
  />
)

export const CheckBox = ({
  text,
  checked = false,
  onChange = () => {},
  ...otherProps
}) => (
  <label className={styles.checkbox} {...otherProps}>
    <input
      type="checkbox"
      className={styles.checkboxInput}
      onChange={ev => onChange(ev.target.checked)}
      checked={checked}
    />
    <div className={styles.checkboxBox}>
      {checked && <Checkmark style={{ width: "80%", height: "80%" }} />}
    </div>
    <p className={styles.checkboxText}>{text}</p>
  </label>
)

export const IconNumberInput = ({
  value = 0,
  onChange = () => {},
  icon,
  step,
  ...otherProps
}) => (
  <div className={styles.iconNumberContainer} {...otherProps}>
    <div className={styles.icon}>{icon}</div>
    <input
      type="number"
      className={styles.input}
      value={value}
      onChange={ev => onChange(ev.target.value)}
      step={step}
    />
  </div>
)
