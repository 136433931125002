import React from "react"
import * as styles from "./modal.module.scss"

export default ({ modalText, buttonText, buttonEvent }) => (
  <div className={styles.modalWrapper}>
    <div className={styles.modalContainer}>
      <div className={styles.modalText}>{modalText}</div>
      {buttonText && (
        <button className={styles.modalButton} onClick={buttonEvent}>
          {buttonText}
        </button>
      )}
    </div>
  </div>
)
