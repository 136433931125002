import React from "react"
import * as styles from "./payment-method-screen.module.scss"

import { Row } from "../layout"
import LabeledSeperator from "./labeled-seperator"

import mastercardIcon from "../../images/icons/mastercard.svg"
import visaIcon from "../../images/icons/visa.svg"
import amexIcon from "../../images/icons/amex.svg"
// import applePayIcon from "../../images/icons/apple_pay.svg"
import paypalIcon from "../../images/icons/paypal.svg"

const MethodContainer = ({
  children,
  onClick = () => {},
  selected = false,
}) => (
  <button
    className={selected ? styles.selected : styles.paymentMethodButton}
    onClick={onClick}
  >
    {children}
  </button>
)

export default ({ onSelect = () => {}, selectedMethod, ...otherProps }) => {
  return (
    <div className={styles.container} {...otherProps}>
      <h2 className={styles.heading}>Zahlungsmethode</h2>
      <Row>
        <MethodContainer
          onClick={() => onSelect("creditcard")}
          selected={selectedMethod === "creditcard"}
        >
          <img
            src={mastercardIcon}
            alt="Mastercard"
            className={styles.paymentMethodIcon}
          />
          <img src={visaIcon} alt="VISA" className={styles.paymentMethodIcon} />
          <img src={amexIcon} alt="Amex" className={styles.paymentMethodIcon} />
        </MethodContainer>
      </Row>
      <LabeledSeperator style={{ width: "50%" }} label="oder" />
      <Row>
        {/* <MethodContainer
        onClick={() => onSelect("apple_pay")}
        selected={selectedMethod === "apple_pay"}
      >
        <img
          src={applePayIcon}
          alt="Apple Pay"
          style={{ width: "50px", height: "50px" }}
        />
      </MethodContainer> */}
        <MethodContainer
          onClick={() => onSelect("paypal")}
          selected={selectedMethod === "paypal"}
        >
          <img
            src={paypalIcon}
            alt="PayPal"
            style={{ width: "100px", height: "50px" }}
          />
        </MethodContainer>
      </Row>
      <LabeledSeperator style={{ width: "50%" }} label="oder" />
      <Row>
        <MethodContainer
          onClick={() => onSelect("sepa")}
          selected={selectedMethod === "sepa"}
        >
          SEPA-Lastschrift
        </MethodContainer>
      </Row>
    </div>
  )
}
