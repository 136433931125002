import React, { useState, useEffect } from "react"
import * as styles from "./sepa-form-screen.module.scss"

import loadingGif from "../../images/ajax-loader.svg"

import { IbanElement, injectStripe } from "react-stripe-elements"

import { TextInput } from "../forms/input-field"
import { Row } from "../layout"
import Modal from "../modal"

function getDarkModeStyles() {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    var darkModeStyle = {
      base: {
        color: "#FFFFFF",
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "17px",
        iconColor: "#FFFFFF",
      },
      invalid: {
        color: "#ff747f",
        fontSmoothing: "antialiased",
        iconColor: "#ff747f",
        ":-webkit-autofill": {
          color: "#ff747f",
        },
      },
    }
    return darkModeStyle
  } else {
    var lightModeStyle = {
      base: {
        color: "#1d1d1f",
        fontSmoothing: "antialiased",

        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        iconColor: "#1d1d1f",
        fontSize: "17px",
      },
      invalid: {
        color: "#ff747f",
        fontSmoothing: "antialiased",
        iconColor: "#ff747f",
        ":-webkit-autofill": {
          color: "#ff747f",
        },
      },
    }
    return lightModeStyle
  }
}

export default injectStripe(
  ({
    data = { name: "", email: "" },
    onDataChange = () => {},
    detailsAlreadyEntered = true,
    reenterPressed = () => {},
    stripe,
    elements,
    ...otherProps
  }) => {
    function updateData(diff) {
      onDataChange({
        name: data.name,
        email: data.email,
        paymentMethodCreator: () =>
          stripe.createPaymentMethod({
            type: "sepa_debit",
            sepa_debit: elements.getElement("iban"),
            billing_details: {
              name: data.name,
              email: data.email,
            },
          }),
        ...diff,
      })
    }

    const expectedNumLoadedInputs = 1
    const [numLoadedInputs, setNumLoadedInputs] = useState(0)

    function increaseNumLoadedInputs() {
      setNumLoadedInputs(numLoadedInputs + 1)
    }

    const finishedLoading = numLoadedInputs >= expectedNumLoadedInputs

    const [longerThanExpected, setLongerThanExpected] = useState(false)

    useEffect(() => {
      const handle = setTimeout(() => {
        setLongerThanExpected(!finishedLoading)
      }, 5000)
      return () => {
        clearTimeout(handle)
      }
    }, [finishedLoading])

    return (
      <div className={styles.container} {...otherProps}>
        <h2 className={styles.heading} style={{ marginBottom: "10px" }}>
          SEPA-Lastschrift
        </h2>
        <form
          className={
            detailsAlreadyEntered || !finishedLoading
              ? styles.hidden
              : styles.show
          }
        >
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            <TextInput
              type="email"
              required
              placeholder="Name"
              style={{ width: "100%" }}
              value={data.name}
              onChange={name => updateData({ name })}
            />
          </Row>
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            <TextInput
              type="email"
              placeholder="E-Mail"
              style={{ width: "100%" }}
              value={data.email}
              onChange={email => updateData({ email })}
            />
          </Row>
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            <IbanElement
              className={styles.stripeElement}
              style={getDarkModeStyles()}
              onChange={() => updateData()}
              onReady={() => increaseNumLoadedInputs()}
              supportedCountries={["SEPA"]}
              placeholder="AT00 0000 0000 0000 0000"
            />
          </Row>
        </form>
        {detailsAlreadyEntered && (
          <Modal
            modalText="Deine IBAN wurde zur Verwendung hinterlegt und
                kann aus Sicherheitsgründen nicht nochmal angezeigt werden."
            buttonText="Neu eingeben"
            buttonEvent={reenterPressed}
          />
        )}
        {!detailsAlreadyEntered && !finishedLoading && (
          <Modal
            modalText={
              <div className={styles.loadingModal}>
                <div className={styles.loadingRow}>
                  <p>
                    <strong>Lade sichere Eingabefelder</strong>
                  </p>
                  <img
                    alt="Lädt..."
                    src={loadingGif}
                    className={styles.loader}
                    style={{ marginLeft: "8px" }}
                  />
                </div>
                {longerThanExpected && (
                  <p style={{ marginTop: "10px", textAlign: "left" }}>
                    Das dauert länger als erwartet. Möglicherweise verhindert
                    eine Browser-Erweiterung das Laden der sicheren Elemente.
                    Versuche, alle Browser-Erweiterungen zu deaktivieren, die
                    sich in Eingabefelder einmischen könnten (zB.
                    Passwortmanager).
                  </p>
                )}
              </div>
            }
          />
        )}
      </div>
    )
  }
)
