import React from "react"
import * as styles from "./rainbow-progress-bar.module.scss"
import classNames from "classnames"

export default ({ progress = 6, ...otherProps }) => (
  <div className={styles.container} {...otherProps}>
    {[...Array(6).keys()].map(i => (
      <div
        key={i}
        className={classNames(styles.bar, i < progress && styles.colored)}
      ></div>
    ))}
  </div>
)
