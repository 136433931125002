import React, { Fragment, useState, useEffect, useCallback } from "react"

import { Helmet } from "react-helmet"
import { createBrowserHistory, createMemoryHistory } from "history"
import * as Sentry from "@sentry/browser"
import AppBase from "../../util/app-base"

import { StripeProvider, Elements } from "react-stripe-elements"
import Script from "react-load-script"

import { useAlert } from "react-alert"
import { handleErrorAndGetDisplayMessage } from "../../util/stripe-error"

import { InternalRainbowLink } from "../../components/rainbow-link"
import GA from "react-ga"

import DonationPageLayout from "../../components/spenden-page/donation-page-layout"

import AmountChooser, {
  round2digits,
} from "../../components/spenden-page/amount-screen"
import PaymentMethodSelector from "../../components/spenden-page/payment-method-screen"
import CreditCardForm from "../../components/spenden-page/credit-card-form-screen"
import SepaForm from "../../components/spenden-page/sepa-form-screen"
import AdditionalInfoForm from "../../components/spenden-page/additional-info-screen"
import DonationSummary from "../../components/spenden-page/donation-summary-screen"
import StripeCompletionScreen from "../../components/spenden-page/stripe-completion-screen"
import PaypalCompletionScreen from "../../components/spenden-page/paypal-completion-screen"

/* TODO: add analytics */

const WithStripe = ({ children }) => {
  const [stripe, setStripe] = useState(null)

  if (!stripe) {
    return (
      <Script
        url="https://js.stripe.com/v3/"
        onError={() => {
          Sentry.captureMessage(
            "Failed to load Stripe.js from https://js.stripe.com/v3/"
          )
        }}
        onLoad={() => setStripe(window.Stripe(process.env.STRIPE_PUBLIC_KEY))}
      />
    )
  }

  return (
    <Fragment>
      <StripeProvider stripe={stripe}>
        <Elements>{children}</Elements>
      </StripeProvider>
    </Fragment>
  )
}

const defaultScreen = {
  name: "",
  progress: 0,
  onContinue: context => context.state,
  onBack: context => context.pop(),
  Component: context => (
    <p style={{ color: "purple" }}>Render function implemented</p>
  ),
  continueButtonEnabled: state => true,
  backButtonEnabled: state => true,
  continueButtonLoading: state => false,
  continueButtonText: state => "Weiter",
}

const screens = {
  chooseAmount: {
    name: "betrag",
    progress: 1,
    onContinue: ({ state, push, alert }) => {
      if (state.amount < 0.5 || state.amount > 999999.99) {
        alert.show(
          "Bitte gib einen Betrag zwischen 0,50€ und 999.999,99€ an.",
          {
            type: "error",
          }
        )
        return
      }

      GA.event({
        category: "Amount",
        action: "Confirm",
        value: state.amount,
      })

      /*
            paypal(x)=1.2x*(1)/(100)+0.35
            stripe(x)=1.4x*(1)/(100)+0.25

            paypal(x)=stripe(x) -> x = 50
            x < 50 -> stripe cheaper
            x = 50 -> paypal probably more convenient
            x > 50 -> paypal cheaper
          */
      /*const optimalPaymentMethod = state.amount < 50 ? "creditcard" : "paypal"
      setState({
        ...state,
        paymentMethod: optimalPaymentMethod,
      })*/
      push(screens.pickPaymentMethod)
    },
    backButtonEnabled: () => false,
    Component: ({ state, setState }) => {
      function onAmountChange(newAmount) {
        if (newAmount !== state.amount) {
          setState({
            ...state,
            amount: newAmount,
          })
        }
      }
      return (
        <AmountChooser onChange={onAmountChange} initialAmount={state.amount} />
      )
    },
  },
  pickPaymentMethod: {
    name: "zahlungsmethode",
    progress: 2,
    onContinue: ({ push, state }) => {
      GA.event({
        category: "Payment Method",
        action: "Confirm",
        label: state.paymentMethod,
      })

      switch (state.paymentMethod) {
        case "creditcard":
          push(screens.creditCardForm)
          break
        case "sepa":
          push(screens.sepaForm)
          break
        case "paypal":
          push(screens.donationSummary)
          break
        default:
          console.error(`Unknown payment method ${state.paymentMethod}`)
      }
    },
    continueButtonEnabled: state => typeof state.paymentMethod === "string",
    Component: ({ state, setState }) => {
      function onPaymentMethodSelected(newMethod) {
        if (newMethod !== state.paymentMethod) {
          GA.event({
            category: "Payment Method",
            action: "Select",
            label: newMethod,
          })

          setState({ ...state, paymentMethod: newMethod })
        }
      }
      return (
        <PaymentMethodSelector
          selectedMethod={state.paymentMethod}
          onSelect={onPaymentMethodSelected}
        />
      )
    },
  },
  creditCardForm: {
    name: "kreditkarte",
    progress: 3,
    continueButtonLoading: state => state.creditCardInfo.loading,
    onContinue: async ({ state, setState, push, alert }) => {
      if (!!state.creditCardInfo.paymentMethod.id) {
        GA.event({
          category: "Creditcard",
          action: "Confirm",
          label: "Existing",
        })

        push(screens.donationSummary)
        return
      }

      GA.event({
        category: "Creditcard",
        action: "Try Confirm",
      })

      const paymentMethodCreator = state.creditCardInfo.paymentMethodCreator
      if (typeof paymentMethodCreator !== "function") {
        GA.event({
          category: "Creditcard",
          action: "Fail Confirm",
          label: "no credit card details",
        })

        alert.show("Bitte gib deine Kreditkartendaten ein.", { type: "error" })
        return
      }

      if (state.creditCardInfo.name === "") {
        GA.event({
          category: "Creditcard",
          action: "Fail Confirm",
          label: "no name",
        })

        alert.show("Bitte gib deinen Namen an.", { type: "error" })
        return
      }

      setState({
        ...state,
        creditCardInfo: {
          ...state.creditCardInfo,
          loading: true,
        },
      })

      const result = await paymentMethodCreator()

      setState({
        ...state,
        creditCardInfo: {
          ...state.creditCardInfo,
          loading: false,
        },
      })

      if (result.error) {
        GA.event({
          category: "Creditcard",
          action: "Fail Confirm",
          label: result.error.code,
        })

        // error during token creation
        const errorMessage = handleErrorAndGetDisplayMessage(result.error)
        alert.show(errorMessage, { type: "error" })
        return
      }

      GA.event({
        category: "Creditcard",
        action: "Confirm",
        label: "New",
      })

      setState({
        ...state,
        paymentMethod: "creditcard",
        creditCardInfo: {
          ...state.creditCardInfo,
          paymentMethod: result.paymentMethod,
        },
      })
      push(screens.donationSummary)
    },
    Component: ({ state, setState }) => {
      function handleDataChange(diff) {
        setState({
          ...state,
          creditCardInfo: {
            ...state.creditCardInfo,
            ...diff,
          },
        })
      }

      function resetPaymentMethod() {
        GA.event({
          category: "Creditcard",
          action: "Reset",
        })

        setState({
          ...state,
          creditCardInfo: {
            ...state.creditCardInfo,
            paymentMethod: {},
            paymentMethodCreator: null,
          },
        })
      }

      return (
        <WithStripe>
          <CreditCardForm
            name={state.creditCardInfo.name}
            onDataChange={handleDataChange}
            detailsAlreadyEntered={!!state.creditCardInfo.paymentMethod.id}
            reenterPressed={resetPaymentMethod}
          />
        </WithStripe>
      )
    },
  },
  sepaForm: {
    name: "sepa",
    progress: 3,
    continueButtonLoading: state => state.sepaInfo.loading,
    onContinue: async ({ state, setState, push, alert }) => {
      if (!!state.sepaInfo.paymentMethod.id) {
        GA.event({
          category: "Sepa",
          action: "Confirm",
          label: "Existing",
        })

        push(screens.donationSummary)
        return
      }

      GA.event({
        category: "Sepa",
        action: "Try Confirm",
      })

      const paymentMethodCreator = state.sepaInfo.paymentMethodCreator
      if (typeof paymentMethodCreator !== "function") {
        GA.event({
          category: "Sepa",
          action: "Fail Confirm",
          label: "no iban",
        })

        alert.show("Bitte gib deinen IBAN ein.", { type: "error" })
        return
      }

      if (state.sepaInfo.name === "") {
        GA.event({
          category: "Sepa",
          action: "Fail Confirm",
          label: "no name",
        })

        alert.show("Bitte gib deinen Namen an.", { type: "error" })
        return
      }

      if (state.sepaInfo.email === "") {
        GA.event({
          category: "Sepa",
          action: "Fail Confirm",
          label: "no email",
        })

        alert.show("Bitte gib deine E-Mail Adresse an.", { type: "error" })
        return
      }

      setState({
        ...state,
        sepaInfo: {
          ...state.sepaInfo,
          loading: true,
        },
      })
      const result = await state.sepaInfo.paymentMethodCreator()

      setState({
        ...state,
        sepaInfo: {
          ...state.sepaInfo,
          loading: false,
        },
      })

      if (result.error) {
        GA.event({
          category: "Sepa",
          action: "Fail Confirm",
          label: result.error.code,
        })

        // error during token creation
        const errorMessage = handleErrorAndGetDisplayMessage(result.error)
        alert.show(errorMessage, { type: "error" })
        return
      }

      GA.event({
        category: "Sepa",
        action: "Confirm",
        label: "New",
      })

      setState({
        ...state,
        paymentMethod: "sepa",
        sepaInfo: {
          ...state.sepaInfo,
          paymentMethod: result.paymentMethod,
        },
      })
      push(screens.donationSummary)
    },
    Component: ({ state, setState }) => {
      function handleDataChange(diff) {
        setState({
          ...state,
          sepaInfo: {
            ...state.sepaInfo,
            ...diff,
          },
        })
      }

      function resetPaymentMethod() {
        GA.event({
          category: "Sepa",
          action: "Reset",
        })

        setState({
          ...state,
          sepaInfo: {
            ...state.sepaInfo,
            paymentMethod: {},
            paymentMethodCreator: null,
          },
        })
      }

      return (
        <WithStripe>
          <SepaForm
            data={state.sepaInfo}
            onDataChange={handleDataChange}
            detailsAlreadyEntered={!!state.sepaInfo.paymentMethod.id}
            reenterPressed={resetPaymentMethod}
          />
        </WithStripe>
      )
    },
  },
  additionalInfoForm: {
    name: "zusatzinfo",
    progress: 4,
    onContinue: ({ push }) => push(screens.donationSummary),
    Component: ({ state, setState }) => {
      const {
        email,
        newsLetterAccepted,
        sponsorListRequested,
        name,
      } = state.additionalInfo

      function handleChange(diff) {
        setState({
          ...state,
          additionalInfo: { ...state.additionalInfo, ...diff },
        })
      }

      return (
        <AdditionalInfoForm
          email={email}
          newsLetterAccepted={newsLetterAccepted}
          sponsorListRequested={sponsorListRequested}
          name={name}
          onChange={handleChange}
        />
      )
    },
  },
  donationSummary: {
    name: "zusammenfassung",
    progress: 5,
    onContinue: ({ state, push }) => {
      GA.event({
        category: "Summary",
        action: "Confirm",
        label: state.paymentMethod,
        value: state.amount,
      })

      switch (state.paymentMethod) {
        case "creditcard":
          if (!state.creditCardInfo.paymentMethod.id) {
            push(screens.creditCardForm)
            break
          }

          push(screens.stripeCompletionScreen)
          break
        case "sepa":
          if (!state.sepaInfo.paymentMethod.id) {
            push(screens.sepaForm)
            break
          }

          push(screens.stripeCompletionScreen)
          break
        case "paypal":
          push(screens.paypalCompletionScreen)
          break
        default:
          push(screens.pickPaymentMethod)
      }
    },
    continueButtonText: () => "Weiter",
    Component: ({ state }) => {
      const numFlags = state.amount / process.env.EUROS_PER_FLAG
      const roundedNumFlags = round2digits(numFlags)

      const createPaymentMethodSummary =
        {
          creditcard: state => (
            <Fragment>
              <h3>Kredit- oder Debitkarte</h3>
              {state.creditCardInfo.paymentMethod.id ? (
                <Fragment>
                  <p>{state.creditCardInfo.name}</p>
                  <p>
                    XXXX XXXX XXXX{" "}
                    {state.creditCardInfo.paymentMethod?.card?.last4}
                  </p>
                </Fragment>
              ) : (
                  <p>Keine Kreditkartendaten angegeben.</p>
                )}
            </Fragment>
          ),
          paypal: state => <h3>PayPal</h3>,
          sepa: state => (
            <Fragment>
              <h3>SEPA-Lastschrift</h3>
              {state.sepaInfo.paymentMethod.id ? (
                <Fragment>
                  <p>{state.sepaInfo.name}</p>
                  <p>
                    {state.sepaInfo.paymentMethod.sepa_debit?.country}XXXX XXXX
                    XXXX XXXX XXXX{" "}
                    {state.sepaInfo.paymentMethod.sepa_debit?.last4}
                  </p>
                </Fragment>
              ) : (
                  <p>Keine SEPA-Daten angegeben.</p>
                )}
            </Fragment>
          ),
        }[state.paymentMethod] || (() => <h3>Keine Zahlungsmethode gewählt</h3>)

      return (
        <DonationSummary
          amount={state.amount}
          flags={roundedNumFlags}
          flagsRounded={numFlags !== roundedNumFlags}
          paymentMethod={createPaymentMethodSummary(state)}
        />
      )
    },
  },
  stripeCompletionScreen: {
    name: "stripeAbschluss",
    progress: 6,
    backButtonEnabled: () => false,
    continueButtonEnabled: () => false,
    Component: ({ state, setState, push }) => {
      const paymentMethod = {
        creditcard: state.creditCardInfo.paymentMethod,
        sepa: state.sepaInfo.paymentMethod,
      }[state.paymentMethod]

      function cleanupState() {
        const cleanState = {
          ...state,
          creditCardInfo: {
            ...state.creditCardInfo,
            paymentMethod: {},
          },
          sepaInfo: {
            ...state.sepaInfo,
            paymentMethod: {},
          },
        }
        setState(cleanState)
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(cleanState))
      }

      return (
        <WithStripe>
          <StripeCompletionScreen
            amount={state.amount}
            cleanup={cleanupState}
            busyMessage={<h2>Verarbeitung...</h2>}
            noDataMessage={
              <Fragment>
                <h2>Wir konnten keine Zahlungsdaten finden.</h2>
                <h3>
                  Bist du möglicherweise direkt per Link auf diese Seite
                  gekommen?
                </h3>
                {/* TODO: crashes when user clicks 'Spenden' button and then navigates back here */}
                <InternalRainbowLink
                  onClick={() => {
                    GA.event({
                      category: "Empty Stripe Completion",
                      action: "Donate",
                    })
                    push(screens.chooseAmount)
                  }}
                  href="/spenden/#/betrag"
                  title="Spenden"
                  style={{ marginTop: "20px" }}
                />
              </Fragment>
            }
            successMessage={
              <Fragment>
                <h2>Vielen herzlichen Dank!</h2>
                <InternalRainbowLink
                  href="/"
                  title="Zurück zur Startseite"
                  style={{ marginTop: "20px" }}
                />
              </Fragment>
            }
            sepaProcessingMessage={
              <Fragment>
                <h2>Vielen herzlichen Dank!</h2>
                <h3>Die Zahlung wurde angewiesen.</h3>
                <InternalRainbowLink
                  href="/"
                  title="Zurück zur Startseite"
                  style={{ marginTop: "20px" }}
                />
              </Fragment>
            }
            getFailureMessage={errorMessage => (
              <Fragment>
                <h2 style={{ textAlign: "center" }}>{errorMessage}</h2>
                <InternalRainbowLink
                  onClick={() => {
                    GA.event({
                      category: "Stripe Failure",
                      action: "Retry",
                    })
                    push(screens.pickPaymentMethod)
                  }}
                  href="/spenden/#/zahlungsmethode"
                  title="Nochmal versuchen"
                  style={{ marginTop: "20px" }}
                />
              </Fragment>
            )}
            paymentMethod={paymentMethod}
          />
        </WithStripe>
      )
    },
  },
  paypalCompletionScreen: {
    name: "paypalAbschluss",
    progress: 6,
    backButtonEnabled: () => false,
    continueButtonEnabled: () => false,
    Component: ({ state }) => {
      return <PaypalCompletionScreen amount={state.amount} />
    },
  },
}

function findScreen(name) {
  if (name === "" || name.startsWith("spenden")) {
    Sentry.captureEvent({
      message: "Handled initial screen name",
      level: "info",
      extra: {
        name,
      },
    })
    return screens.chooseAmount
  }

  const screen = Object.values(screens).find(screen => screen.name === name)
  if (typeof screen === "object") {
    return screen
  }

  console.error("Unknown screen:", name)
  Sentry.captureMessage("Unknown screen: " + name)
  return screens.chooseAmount
}

const sessionStorageKey = "spenden"
const Page = () => {
  // necessary for server-side rendering
  const [history] = useState(
    typeof window !== "undefined"
      ? createBrowserHistory({
        basename: "spenden/#",
      })
      : createMemoryHistory()
  )

  const [forceRerenderState, setForceRerenderState] = useState(0)
  const forceRerender = useCallback(
    () => setForceRerenderState(forceRerenderState + 1),
    [forceRerenderState, setForceRerenderState]
  )

  const [state, setState] = useState(
    (typeof window !== "undefined" &&
      JSON.parse(sessionStorage.getItem(sessionStorageKey))) || {
      amount: process.env.INITIAL_DONATION_AMOUNT,
      paymentMethod: null,
      creditCardInfo: {
        loading: false,
        name: "",
        // only temporarily used, not saved in session storage
        paymentMethodCreator: null,
        paymentMethod: {},
      },
      sepaInfo: {
        loading: false,
        name: "",
        email: "",
        // only temporarily used, not saved in session storage
        paymentMethodCreator: null,
        paymentMethod: {},
      },
      additionalInfo: {
        email: "",
        newsLetterAccepted: false,
        sponsorListRequested: false,
        name: "",
      },
    }
  )

  // subscribe to location changes
  useEffect(() => {
    const unlisten = history.listen(() => {
      GA.pageview(window.location.pathname + window.location.hash)

      // force rerender on changed location
      forceRerender()
    })
    return unlisten
  }, [forceRerender, history])

  // save state to session storage
  useEffect(() => {
    typeof window !== "undefined" &&
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(state))
  }, [state])

  // inital page
  useEffect(() => {
    if (window.location.hash.startsWith("#/")) {
      GA.pageview(window.location.pathname + window.location.hash)
    } else {
      history.replace("/" + screens.chooseAmount.name)
    }
  }, [history])

  // the first character is a '/'
  const screenName = history.location.pathname.substring(1)
  const currentScreen = findScreen(screenName)
  const {
    progress,
    Component,
    onBack,
    onContinue,
    backButtonEnabled,
    continueButtonEnabled,
    continueButtonLoading,
    continueButtonText,
  } = {
    ...defaultScreen,
    ...currentScreen,
  }

  function push(element) {
    history.push("/" + element.name)
  }

  function pop() {
    history.goBack()
  }

  const context = {
    state,
    setState,
    push,
    pop,
    alert: useAlert(),
  }

  async function continuePressed() {
    GA.event({
      category: "Navigation",
      action: "Continue",
    })
    onContinue(context)
  }

  async function backPressed() {
    GA.event({
      category: "Navigation",
      action: "Back",
    })
    onBack(context)
  }

  return (
    <Fragment>
      <Helmet>
        <link rel="preload" href="https://js.stripe.com/v3/" as="script" />
      </Helmet>
      <DonationPageLayout
        progress={progress}
        showPocket={progress <= 2}
        leftButton={{
          text: "Zurück",
          onClick: () => backPressed(),
          enabled: backButtonEnabled(state),
        }}
        labelText={progress > 0 && `Schritt ${progress}`}
        rightButton={{
          text: continueButtonText(state),
          onClick: () => continuePressed(),
          enabled: continueButtonEnabled(state),
          loading: continueButtonLoading(state),
        }}
      >
        <Component {...context} />
      </DonationPageLayout>
    </Fragment>
  )
}

export default () => (
  <AppBase title="FLAGincluded - Spenden">
    {/* Prevent server-side rendering of page because of hydration issue (https://github.com/facebook/react/issues/13260) */}
    {typeof window !== "undefined" && <Page />}
  </AppBase>
)
