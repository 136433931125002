import errorMessages from "../data/stripe-error-messages.json"
import * as Sentry from "@sentry/browser"

export function handleErrorAndGetDisplayMessage(stripeError) {
  switch (stripeError.type) {
    case "api_connection_error": // Stripe Errors (not exposed to the user)
    case "api_error":
    case "idempotency_error": {
      Sentry.captureException(stripeError, "Stripe Error")
      const message =
        errorMessages[stripeError.code] ||
        "Fehler in der Datenübermittlung mit unserem Zahlungsanbieter. Dieser Vorfall wurde gemeldet und wird so bald als möglich behoben. Versuch es bitte nochmal oder verwende bis dahin PayPal oder manuelle Überweisung."
      return message
    }

    case "rate_limit_error": // Rate Limit Error (only exposed to the user)
      return "Es wurden zu viele Anfragen in zu kurzer Zeit gestellt. Warte bitte kurz und versuche es dann erneut."

    case "card_error": // Stripe Validation Error (exposed to the user)
    case "validation_error":
    case "invalid_request_error": {
      const message = errorMessages[stripeError.code]

      if (message) {
        return message
      } else {
        console.log("unknown stripe error:", stripeError.code)
        Sentry.captureException(stripeError, "Unkown Stripe Validation Error")
        return "Deine Angaben sind leider ungültig. Bitte überprüfe sie und versuche es dann erneut."
      }
    }
    default:
      return "Ein unbekannter Fehler ist aufgetreten. Dieser Vorfall wurde gemeldet und wird so bald als möglich behoben. Versuche es bitte nochmal oder verwende bis dahin PayPal oder manuelle Überweisung."
  }
}
