import React, { useState, useEffect } from "react"
import * as styles from "./completion-screen.module.scss"

import GA from "react-ga"
import * as Sentry from "@sentry/browser"

import { injectStripe } from "react-stripe-elements"

import { createStripeDonation } from "../../util/api"
import { handleErrorAndGetDisplayMessage } from "../../util/stripe-error"

export default injectStripe(
  ({
    amount,
    noDataMessage,
    busyMessage,
    sepaProcessingMessage,
    successMessage,
    getFailureMessage = () => "",
    stripe,
    paymentMethod,
    cleanup = () => {},
    ...otherProps
  }) => {
    // schedule cleanup tasks
    useEffect(
      () => () => {
        if (typeof paymentMethod?.id === "string") {
          cleanup()
        }
      },
      [cleanup, paymentMethod]
    )

    const [statusInfo, setStatusInfo] = useState({
      status: "init",
      errorMessage: "",
    })

    useEffect(() => {
      if (typeof paymentMethod?.id !== "string") {
        return
      }

      ;(async () => {
        try {
          setStatusInfo({ status: "processing", errorMessage: "" })

          let result = await createStripeDonation(amount)

          if (result.error) {
            switch (result.error.code) {
              case "stripe_error":
                const stripeError = result.internal_error
                const message = handleErrorAndGetDisplayMessage(stripeError)
                return void setStatusInfo({
                  status: "failure",
                  errorMessage: message,
                })
              case "input_error":
              default:
                Sentry.captureException(result.error)
                return void setStatusInfo({
                  status: "failure",
                  errorMessage:
                    "Ein unbekannter Fehler ist aufgetreten. Dieser Vorfall wurde gemeldet und wird so bald als möglich behoben. Versuche es bitte nochmal oder verwende bis dahin PayPal oder manuelle Überweisung.",
                })
            }
          }

          const clientSecret = result.clientSecret

          let paymentIntentConfirmation
          switch (paymentMethod.type) {
            case "card":
              paymentIntentConfirmation = stripe.confirmCardPayment(
                clientSecret,
                {
                  payment_method: paymentMethod.id,
                }
              )
              break
            case "sepa_debit":
              paymentIntentConfirmation = stripe.confirmSepaDebitPayment(
                clientSecret,
                {
                  payment_method: paymentMethod.id,
                }
              )
              break
            default:
              throw Error("Unknown payment method " + paymentMethod.type)
          }

          result = await paymentIntentConfirmation

          if (result.error) {
            const message = handleErrorAndGetDisplayMessage(result.error)

            return void setStatusInfo({
              status: "failure",
              errorMessage: message,
            })
          }

          if (result.paymentIntent.status === "processing") {
            return void setStatusInfo({
              status: "sepaProcessing",
              errorMessage: "",
            })
          }

          setStatusInfo({ status: "success", errorMessage: "" })
        } catch (error) {
          Sentry.captureException(error)

          setStatusInfo({
            status: "failure",
            errorMessage:
              "Ein unbekannter Fehler ist aufgetreten. Er wurde gemeldet und wird so bald als möglich behoben.",
          })
        }
      })()
    }, [stripe, paymentMethod, amount])

    useEffect(() => {
      GA.event({
        category: "Stripe Processing",
        action: statusInfo.status,
        label: statusInfo.errorMessage,
      })
    }, [statusInfo])

    if (!paymentMethod?.id) {
      return (
        <div className={styles.container} {...otherProps}>
          {noDataMessage}
        </div>
      )
    }

    switch (statusInfo.status) {
      case "init":
      case "processing":
        return (
          <div className={styles.container} {...otherProps}>
            {busyMessage}
          </div>
        )
      case "success":
        return (
          <div className={styles.container} {...otherProps}>
            {successMessage}
          </div>
        )
      case "sepaProcessing":
        return (
          <div className={styles.container} {...otherProps}>
            {sepaProcessingMessage}
          </div>
        )
      case "failure":
        return (
          <div className={styles.container} {...otherProps}>
            {getFailureMessage(statusInfo.errorMessage)}
          </div>
        )
      default:
        return <div>Unhandled case: {statusInfo}</div>
    }
  }
)
