import React from "react"
import * as styles from "./donation-summary-screen.module.scss"

import LabeledSeperator from "./labeled-seperator"

export default ({
  amount,
  flags,
  flagsRounded = false,
  paymentMethod,
  ...otherProps
}) => {
  return (
    <div className={styles.container} {...otherProps}>
      <h2 className={styles.heading}>Zusammenfassung</h2>
      <div className={styles.subSection}>
        <p className={styles.amountText}>€{amount.toLocaleString("de-AT")}</p>
        <p className={styles.flagText}>
          Entspricht {flagsRounded && "ca. "}
          {flags.toLocaleString("de-AT")} {flags === 1 ? "Flagge" : "Flaggen"}
        </p>
      </div>
      <LabeledSeperator
        style={{ width: "50%", marginTop: "20px", marginBottom: "20px" }}
        label="mit"
      />
      <div className={styles.subSection}>{paymentMethod}</div>
    </div>
  )
}
