import React from "react"
import * as styles from "./additional-info-screen.module.scss"

import { TextInput, CheckBox } from "../forms/input-field"

export default ({
  email,
  newsLetterAccepted,
  sponsorListRequested,
  name,
  onChange = () => {},
  ...otherProps
}) => {
  return (
    <div className={styles.container} {...otherProps}>
      <h2 className={styles.heading}>Zusätzliche Infos</h2>
      <CheckBox
        style={{ marginTop: "10px", marginBottom: "10px" }}
        text="Ich möchte sporadisch per E-Mail über den Projektfortschritt informiert werden."
        checked={newsLetterAccepted}
        onChange={newsLetterAccepted => onChange({ newsLetterAccepted })}
      />
      {newsLetterAccepted && (
        <TextInput
          type="text"
          placeholder="E-Mail Adresse"
          value={email}
          onChange={email => onChange({ email })}
          style={{ marginBottom: "10px" }}
        />
      )}
      <CheckBox
        text="Ich möchte namentlich auf der Sponsoren-Seite angeführt werden."
        style={{ marginTop: "10px", marginBottom: "10px" }}
        checked={sponsorListRequested}
        onChange={sponsorListRequested => onChange({ sponsorListRequested })}
      />
      {sponsorListRequested && (
        <TextInput
          value={name}
          type="text"
          onChange={name => onChange({ name })}
          placeholder="Name"
          styles={{ marginBottom: "10px" }}
        />
      )}
    </div>
  )
}
