import React, { useState, useEffect } from "react"
import * as styles from "./amount-screen.module.scss"

import GA from "react-ga"

import { IconNumberInput } from "../forms/input-field"

import FlagLogo from "../../images/flag.inline.svg"
import EqualSign from "../../images/equal.inline.svg"

const eurosPerFlag = process.env.EUROS_PER_FLAG

export function round2digits(n) {
  return Math.floor(100 * n) / 100
}

export default ({ initialAmount = 0, onChange = () => {}, ...otherProps }) => {
  // default value is one flag
  const [amount, internalSetAmmount] = useState(initialAmount)

  // flags are empty if amount is empty, else calculate appropriate number
  const flags = amount === "" ? "" : round2digits(amount / eurosPerFlag)

  // empty field is equivalent to 0
  useEffect(() => onChange(amount === "" ? 0 : amount), [amount, onChange])

  function setAmount(amount) {
    if (amount === "") {
      internalSetAmmount("")
    } else {
      const cleanedAmount = round2digits(Math.abs(amount))

      if (cleanedAmount !== amount) {
        GA.event({
          category: "Amount",
          action: "Select",
          label: "Amount Field",
          value: cleanedAmount,
        })
      }

      internalSetAmmount(cleanedAmount)
    }
  }

  function setFlags(number) {
    if (number === "") {
      setAmount("")
    } else {
      const cleanedAmount = Math.round(
        round2digits(Math.abs(number)) * eurosPerFlag
      )

      if (cleanedAmount !== amount) {
        GA.event({
          category: "Amount",
          action: "Select",
          label: "Flags Field",
          value: cleanedAmount,
        })
      }

      setAmount(cleanedAmount)
    }
  }

  // TODO: Add validation and error messages

  return (
    <div className={styles.container} {...otherProps}>
      <h2 className={styles.heading}>Wie viel möchtest du beitragen?</h2>
      <div className={styles.form}>
        <IconNumberInput
          icon={<FlagLogo className={styles.flagIcon} />}
          value={flags}
          onChange={setFlags}
          step={0.1}
        />
        <EqualSign className={styles.equal} />
        <IconNumberInput
          icon={<p className={styles.euroSign}>€</p>}
          value={amount}
          onChange={setAmount}
          step={10}
        />
      </div>
    </div>
  )
}
