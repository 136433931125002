import React, { Fragment, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { toFullURL } from "../../util"

export default ({ amount }) => {
  const data = useStaticQuery(graphql`
    {
      file(base: { eq: "logo_vert.png" }) {
        publicURL
      }
    }
  `)
  const logoURL = data.file.publicURL

  const formRef = useRef(null)

  useEffect(() => {
    formRef.current.submit()
  }, [formRef])

  return (
    <Fragment>
      <h2 style={{ textAlign: "center" }}>
        Du wirst in Kürze zu PayPal weitergeleitet...
      </h2>
      <form
        action={`https://${process.env.PAYPAL_HOSTNAME}/cgi-bin/webscr`}
        method="post"
        target="_top"
        ref={formRef}
      >
        <input type="hidden" name="cmd" value="_donations" />
        <input type="hidden" name="item_name" value="FLAGincluded" />
        <input type="hidden" name="amount" value={amount} />
        <input type="hidden" name="currency_code" value="EUR" />
        <input
          type="hidden"
          name="business"
          value={process.env.PAYPAL_MERCHANT_EMAIL}
        />
        <input type="hidden" name="image_url" value={toFullURL(logoURL)} />
        <input
          type="hidden"
          name="return"
          value={toFullURL("/spenden/paypal_completed")}
        />
        <input
          type="hidden"
          name="cancel_return"
          value={toFullURL("/spenden/paypal_cancel")}
        />
        {/* TODO: add webhook
        <input
          type="hidden"
          name="notify_url"
          value="..."
        /> */}
      </form>
    </Fragment>
  )
}
