import React, { useState, useEffect } from "react"
import * as styles from "./credit-card-form-screen.module.scss"

import loadingGif from "../../images/ajax-loader.svg"

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from "react-stripe-elements"

import { TextInput } from "../forms/input-field"
import { Row } from "../layout"
import Modal from "../modal"

function getDarkModeStyles() {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    var darkModeStyle = {
      base: {
        color: "#FFFFFF",
        fontFamily:
          'Circular STD, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "17px",
        iconColor: "#FFFFFF",
      },
      invalid: {
        color: "#ff747f",
        fontSmoothing: "antialiased",
        iconColor: "#ff747f",
        ":-webkit-autofill": {
          color: "#ff747f",
        },
      },
    }
    return darkModeStyle
  } else {
    var lightModeStyle = {
      base: {
        color: "#1d1d1f",
        fontSmoothing: "antialiased",

        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        iconColor: "#1d1d1f",
        fontSize: "17px",
      },
      invalid: {
        color: "#ff747f",
        fontSmoothing: "antialiased",
        iconColor: "#ff747f",
        ":-webkit-autofill": {
          color: "#ff747f",
        },
      },
    }
    return lightModeStyle
  }
}

export default injectStripe(
  ({
    name,
    onDataChange = () => {},
    detailsAlreadyEntered = true,
    reenterPressed = () => {},
    stripe,
    elements,
    ...otherProps
  }) => {
    function updateData(diff) {
      onDataChange({
        name: name,
        paymentMethodCreator: () =>
          stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement("cardNumber"),
            billing_details: {
              name: name,
            },
          }),
        ...diff,
      })
    }

    const expectedNumLoadedInputs = 3
    const [numLoadedInputs, setNumLoadedInputs] = useState(0)

    function increaseNumLoadedInputs() {
      setNumLoadedInputs(numLoadedInputs + 1)
    }

    const finishedLoading = numLoadedInputs >= expectedNumLoadedInputs

    const [longerThanExpected, setLongerThanExpected] = useState(false)

    useEffect(() => {
      const handle = setTimeout(() => {
        setLongerThanExpected(!finishedLoading)
      }, 5000)
      return () => {
        clearTimeout(handle)
      }
    }, [finishedLoading])

    return (
      <div className={styles.container} {...otherProps}>
        <h2 className={styles.heading} style={{ marginBottom: "10px" }}>
          Kredit- oder Debitkarte
        </h2>
        <form
          className={
            detailsAlreadyEntered || !finishedLoading
              ? styles.hidden
              : styles.show
          }
        >
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            <TextInput
              type="text"
              placeholder="Vollständiger Name"
              value={name}
              onChange={name => updateData({ name })}
              disabled={detailsAlreadyEntered}
            />
          </Row>
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            <CardNumberElement
              className={styles.stripeElement}
              placeholder="Kartennummer"
              onChange={() => updateData()}
              style={getDarkModeStyles()}
              onReady={() => increaseNumLoadedInputs()}
            />
          </Row>
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            <CardExpiryElement
              className={styles.stripeElement}
              style={getDarkModeStyles()}
              placeholder="Gültig bis"
              onChange={() => updateData()}
              onReady={() => increaseNumLoadedInputs()}
            />
            <div style={{ width: "10px" }}>
              {/* Cannot set margins on Stripe Elements via style attribute */}
            </div>
            <CardCVCElement
              className={styles.stripeElement}
              style={getDarkModeStyles()}
              placeholder="CVC"
              onChange={() => updateData()}
              onReady={() => increaseNumLoadedInputs()}
            />
          </Row>
        </form>
        {detailsAlreadyEntered && (
          <Modal
            modalText="Deine Kreditkartendaten wurden zur Verwendung hinterlegt und
                können aus Sicherheitsgründen nicht nochmal angezeigt werden."
            buttonText="Neu eingeben"
            buttonEvent={reenterPressed}
          />
        )}
        {!detailsAlreadyEntered && !finishedLoading && (
          <Modal
            modalText={
              <div className={styles.loadingModal}>
                <div className={styles.loadingRow}>
                  <p>
                    <strong>Lade sichere Eingabefelder</strong>
                  </p>
                  <img
                    alt="Lädt..."
                    src={loadingGif}
                    className={styles.loader}
                    style={{ marginLeft: "8px" }}
                  />
                </div>
                {longerThanExpected && (
                  <p
                    style={{
                      marginTop: "10px",
                      textAlign: "justify",
                      hyphens: "auto",
                      webkitHyphens: "auto",
                      msHyphens: "auto",
                    }}
                  >
                    Das dauert länger als erwartet. Möglicherweise verhindert
                    eine Browser-Erweiterung das Laden der sicheren Elemente.
                    Versuche, alle Browser-Erweiterungen zu deaktivieren, die
                    sich in Eingabefelder einmischen könnten (zB.
                    Passwortmanager).
                  </p>
                )}
              </div>
            }
          />
        )}
      </div>
    )
  }
)
