import React from "react"
import * as styles from "./info-header.module.scss"

import Header from "../header"

export default ({ infoContent, ...otherProps }) => {
  return (
    <div className={styles.headerContainer} {...otherProps}>
      <Header style={{ flex: "0 1" }} />
      <div className={styles.info}>{infoContent}</div>
    </div>
  )
}
