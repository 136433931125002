import React, { Fragment } from "react"
import * as styles from "./donation-page-layout.module.scss"

import InfoHeader from "./info-header"
import Footer from "../../components/footer"
import RainbowProgressBar from "../../components/spenden-page/rainbow-progress-bar"
import Controls from "../../components/spenden-page/controls"
import SiteEnclosingWrapper from "../site-enclosing-wrapper"

export default ({
  progress,
  leftButton,
  labelText,
  rightButton,
  showPocket,
  children,
}) => (
  <Fragment>
    <SiteEnclosingWrapper>
      <div className={styles.parentDiv}>
        <InfoHeader
          infoContent={
            <Fragment>
              <p>
                <span className={styles.bold}>IBAN</span> AT92 1400 0100 1014
                3980
              </p>
              <p>
                <span className={styles.bold}>BIC</span> BAWAATWW
              </p>
              <p>
                <span className={styles.bold}>Verwendung</span> FLAGincluded
              </p>
            </Fragment>
          }
        />
        <div // <main-content>
          className={styles.mainContent}
        >
          {children}
        </div>
        {/* </main-content> */}
        <div className={styles.controlStrip}>
          <Controls
            leftButton={leftButton}
            labelText={labelText}
            rightButton={rightButton}
          />
          <RainbowProgressBar progress={progress} />
        </div>
      </div>
    </SiteEnclosingWrapper>
    <Footer />
  </Fragment>
)
